<template>
  <div>
    Template Tab Content
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'tabContent',
  props: {
    dealListItem: {
      type: Object,
      default: null,
    },
    isMore: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rowGroup: [],
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      isLanding: 'GET_IS_LANDING_PAGE',
    }),
  },
  watch: {
    isMore() {
      this.rowGroup = [];
      this.dealListItem.list.forEach((element, inx) => {
        this.makeGroup(this.rowGroup, element, inx);
      });
    },
  },
  mounted() {
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
    this.dealListItem.list.forEach((element, inx) => {
      this.makeGroup(this.rowGroup, element, inx);
    });
  },
  destroyed() {
    if (document.querySelector('.st-content')) { document.querySelector('.st-content').removeEventListener('scroll', this.onScroll); }
  },
  methods: {
    makeGroup(group, elm, index) {
      elm[1][0].indexNumber = index;
      if (index % 3 === 0) group.push([]);
      group[Math.floor(index / 3)].push(elm);
    },
    onScroll() {
      if (this.dealListItem.code !== 'vacation_pack') return;
      const pos = Number(document.querySelector('.st-content').scrollTop);
      const baseLinePos = this.$refs['show-more-line'].offsetTop - 100;
      const screenHeight = window.innerHeight;
      if (baseLinePos < pos + screenHeight && !this.state) {
        this.showMoreDeal();
      }
    },
    showMoreDeal() {
      this.$emit('showMoreDeal');
    },
  },
};
</script>
